import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import "../../assets/css/agenda.css";



import dia1 from '../../assets/images/primera_2025/AGENDA/26FEB_b_1reunion.png';
import dia1_select from '../../assets/images/primera_2025/AGENDA/26FEB_az_1reunion.png';
import dia2 from '../../assets/images/primera_2025/AGENDA/27FEB_b_1reunion.png';
import dia2_select from '../../assets/images/primera_2025/AGENDA/27FEB_az_1reunion.png';


import img_reloj from '../../assets/images/primera_2025/AGENDA/ic_reloj_1reunion_2025__editable.png';

import img_reloj_2 from '../../assets/images/primera_2025/AGENDA/ic_reloj2_1reunion_2025_.png';

import { CloseOutlined } from "@ant-design/icons";


const Agenda = (props) => {
  const [dia, setDia] = useState(2);
  const [ver_order, setVerOrder] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const conferencia = [
    {
      dia: 4,
      hora: "09:00 - 17:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Sesiones temáticas simultaneas</span>],
              cargo: [''],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [''],
        },
      ],
      back: "",
    }, {
      dia: 1,
      hora: "16:00 - 18:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Sesión Ordinaria del Consejo Directivo</span>],
              cargo: [''],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [''],
        },
      ],
      back: "",
    }, {
      dia: 1,
      hora: "18:00 - 22:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cocktail y cena de Bienvenida</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [''],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "08:00 - 09:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Registro</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "09:00 - 09:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span className="">Bienvenida y programa estratégico 2025</span>],
              cargo: [<><span className=""><strong>Jorge Avalos,</strong></span> Presidente de la AMPIP<br /><br /></>],
            },
            {
              img: "",
              nombre: [<span className="">Programa estratégico de la AMPIP 2025 (video)<br /><br /></span>],
              cargo: "",
            },{
              img: "",
              nombre: [<span className="">Entrega de premios ganadores del Torneo de Golf AMPIP 2024</span>],
              cargo: "",
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    }, {
      dia: 2,
      hora: "09:30 - 11:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span className="">México Hoy: historias y perspectivas del futuro</span>],
              cargo: [<><span className=""><strong>Carlos Loret de Mola</strong></span></>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "11:00 - 11:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Receso networking</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },{
      dia: 2,
      hora: "11:30 - 12:30 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>The NorthAmerican Idea still exists?</span>],
              cargo: [<><span className=""><strong>Enrique Perret,</strong></span> Director de U.S. Mexico Foundation<br />
                <strong>Jimena Tolama,</strong> Bloomberg Línea</>],
            }
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "12:30 - 13:10 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Infraestructura y tecnología: claves del éxito en la cadena de suministro</span>],
              cargo: [<span><strong>Omar Ramírez,</strong> Director de Fulfillments Mexico, Mercado Libre</span>],
            }
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "13:10 - 14:10 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Parques industriales y política energética:
                decisiones de EE. UU. y consecuencias globales</span>],
              cargo: [<><strong>David Goldwyn,</strong> Goldwyn Strategies<br />
              Entrevistado por: <strong>Javier Ugarte</strong>, FINSA</>],
            }
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "14:10 - 14:15 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Cierre del evento</span>],
              cargo: [<><span className=""><strong>Jorge Avalos,</strong></span> Presidente de la AMPIP</>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 2,
      hora: "14:35- 17:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Comida / networking</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    },
    {
      dia: 4,
      hora: "14:30 - 17:00 Hrs.",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>Traslado y visita a Parque Industrial O’Donnell<br />
                Tlalpan, Ciudad de México</span>],
              cargo: [<i className="color_morado">Cupo limitado a 30 personas.<br />
                Se incluye box lunch durante el traslado.<br />
                Regreso al hotel sede</i>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [""],
          sub: [""],
          desc: [""],
        },
      ],
      back: "",
    }

  ];

  const ordenes = [
    {
      orden: 1, texto: [<>
        <p className="titulo_agenda">Comité de Promoción</p>
        <ol className="desc_agenda" type="1">
          <li>Bienvenida Presidente del Comité – <span className="color_azul_claro_2">José Luis Benitez</span></li>
          <li>Autopresentación de los asistentes</li>
          <li>Presentación de plan de trabajo y acciones relevantes
            <ol type="a">
              <li>Plan de trabajo 2022</li>
            </ol>
          </li>
          <li>Seguimiento y avance de acuerdos
            <ol type="a">
              <li>Información
                <ol type="i">
                  <li>Materiales actualizados</li>
                  <li>Sitio web</li>
                </ol>
              </li>
              <li>Resumen de leads AMPIP</li>
              <li>Ecosistema de promoción AMPIP</li>
              <li>Eventos
                <ol type="i">
                  <li>Promoción con cuerpo diplomático - 20 de octubre 2022</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Nuevos temas:
            <ol type="a">
              <li>Encuesta sobre percepción y tendencias de la IED en México, 2022 - 2023</li>
            </ol>
          </li>
          <li>Propuestas de eventos y capacitación</li>
        </ol>
      </>]
    },
    {
      orden: 2, texto: [<>
        <p className="titulo_agenda">Comité ASG</p>
        <ol className="desc_agenda" type="1">
          <li>Bienvenida Presidente del Comité – <span className="color_azul_claro_2">Karen Mora</span></li>
          <li>Autopresentación de los asistentes</li>
          <li>Presentación nuevo Secretario Técnico - <span className="color_azul_claro_2">Diego Colunga</span></li>
          <li>Presentación de plan de trabajo y acciones <span className="color_azul_claro_2">relevantes – Karen Mora</span>
            <ol className="q">
              <li>Plan de trabajo 2022</li>
              <li>Acciones relevantes
                <ol type="i">
                  <li>Resumen del Sondeo ASG y Sustentabilidad</li>
                  <li>Adhesión AMPIP al Pacto Mundial</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Seguimiento y avance de acuerdos
            <ol type="a">
              <li>Perfil de puesto responsable ASG en parques (técnico y directivo) - <span className="color_azul_claro_2">Mario del Bosque</span></li>
              <li>Guía para homologación de conocimientos - <span className="color_azul_claro_2">Carlos Viesca</span></li>
              <li>Directorio - mapa del ecosistema AMPIP - <span className="color_azul_claro_2">Diego Colunga</span></li>
              <li>Reporte de los seminarios:
                <ol>
                  <li>Certificaciones Leed, Well, Edge - <span className="color_azul_claro_2">Karen Mora</span></li>
                  <li>Código de red - <span className="color_azul_claro_2">Diego Colunga</span></li>
                  <li>Programa Nacional de Auditoría Ambiental PROFEPA - <span className="color_azul_claro_2">Rodolfo Morales</span></li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Nuevos temas:
            <ol>
              <li>Presentación proyecto Mariposa Monarca – <span className="color_azul_claro_2">David Eaton</span> - 15 min</li>
              <li>Compromiso social AMPIP – <span className="color_azul_claro_2">Karen Mora</span> – 5 min
                <ol>
                  <li>Se reciben propuestas hasta el 15 de noviembre</li>
                  <li>Reunión noviembre, Comité ASG decide acción social para 2023</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </>]
    },
    {
      orden: 3, texto: [<>
        <p className="titulo_agenda">Comité de Infraestructura y Seguridad</p>
        <ol className="desc_agenda" type="1">
          <li>Bienvenida Presidente del Comité - <span className="color_azul_claro_2">Daniel Jaimes</span></li>
          <li>Autopresentación de los asistentes</li>
          <li>Seguimiento y avance de acuerdos - <span className="color_azul_claro_2">Lizbeth Contreras</span>
            <ol type="a">
              <li>Reporte general de las reuniones de trabajo del Comité en el periodo mayo-agosto</li>
              <li>Presentación de la nueva versión del documento “Criterios mínimos en seguridad con los que deben contar los parques industriales” (antes Parque Industrial Seguro)</li>
              <li>Presentación del directorio con nuevos consultores expertos en materia de seguridad</li>
              <li>Estatus de la actualización de la Norma Mexicana de Parques Industriales
                <ol type="i">
                  <li>Situación actual del comité (CTNNPI)</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Logística
            <ol type="a">
              <li>Sugerencias de lineas de acción por parte de los asistentes</li>
              <li>Actividades sugeridas</li>
            </ol>
          </li>
          <li>Propuestas de eventos y capacitación</li>
        </ol>
      </>]
    }
  ]

  let visualizar_agenda = conferencia
    .filter((item) => {
      if (item.dia === dia) {
        return true;
      } else {
        return false;
      }
    })
    .map((da, index) => {
      return (
        <Row
          align="top"
          style={{ padding: "15px 0px" }}
          className={da.back}
        >
          <Col xs={{ span: 0, offset: 0 }} md={{ span: 1, offset: 0 }}>
            <img src={img_reloj} style={{ width: '30px' }} />
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 6, offset: 0 }}>
            {da.hora != '' ? <><p className="hora_agenda">{da.hora}</p><br /></> : <></>}
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 1 }}>
            {da.actividad.map((datos) => {
              return (
                <>
                  <Row justify="center" align="middle">
                    <Col xs={24} md={24} style={{ paddingLeft: "10px" }}>
                      {datos.personas != "" ? (
                        <>
                          {datos.personas.map((item) => {
                            return (
                              <>
                                <Row align="middle">
                                  <Col xs={24} md={24}>
                                    <p className="nombre_agenda">
                                      {item.nombre}
                                    </p>
                                    <p className="cargo_nombre">{item.cargo}</p>
                                    {datos.desc != "" && (
                                      <p className="desc_agenda">{datos.desc}</p>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </>
              );
            })}
          </Col>
        </Row>
      );
    });

  let ver_texto_modal = ordenes
    .filter((item) => {
      if (ver_order === item.orden) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          {seccion.texto}
        </Col>
      );
    });

  return (
    <Row className="back_titulo_agenda_">
      <Col span={20} offset={2}>
        <Row justify="center" align="middle" style={{ position: "" }}>
          <Col
            span={24}
            offset={0}
            style={{ position: "", width: "100%", paddingTop: '3%' }}
          >
            <p className="textos_titulos_secciones color_azul">PROGRAMA</p>
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }}>
        <p className="txt_cabos_agenda">
          Hora Ciudad de México
        </p>
        <p className="txt_cabos_agenda color_azul">
          <strong>Código de vestimenta: casual de negocios</strong>
        </p><br />
      </Col>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 6, offset: 2 }}>
        <Row>
          <Col xs={12} md={24} onClick={() => setDia(1)}>
            <img src={dia === 1 ? dia1_select : dia1} style={{ width: '100%' }} />
          </Col>
          <Col xs={12} md={24} onClick={() => setDia(2)}>
            <img src={dia === 2 ? dia2_select : dia2} style={{ width: '100%' }} />
          </Col>
        </Row>

      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} style={{ display: 'none' }}>
        <Row justify="space-around">
          <Col
            xs={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            onClick={() => setDia(1)}
            className={"espacio_dias"
            }
          >
            <div className={dia === 1 ? "back_morado_2 puntero" : "back_gris_oscuro puntero"}>
              <p className={dia === 1 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
                <strong>Día 01</strong>
                <br />
                <span className={dia === 1 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>19 junio, 2024</span>
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            onClick={() => setDia(2)}
            className={"espacio_dias"
            }
          >
            <div className={
              dia === 2 ? "back_morado_2 puntero" : "back_gris_oscuro puntero"
            }>
              <p className={dia === 2 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
                <strong>Día 02</strong>
                <br />
                <span className={dia === 2 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>20 junio, 2024</span>
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 8, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            onClick={() => setDia(3)}
            className={"espacio_dias"
            }
          >
            <div className={
              dia === 3 ? "back_morado_2 puntero" : "back_gris_oscuro puntero"
            }>
              <p className={dia === 3 ? "color_blanco tex_agenda_dia" : "color_azul tex_agenda_dia"}>
                <strong>Día 03</strong>
                <br />
                <span className={dia === 3 ? "color_blanco fecha_text_agenda" : "color_azul fecha_text_agenda"}>21 junio, 2024</span>
              </p>
            </div>
          </Col>
        </Row><br />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 13, offset: 1 }}>
        
        {dia === 1 && 
        <>
        <Row style={{ padding: "15px 0px" }}>
        <Col xs={{ span: 0, offset: 0 }} md={{ span: 1, offset: 0 }}>
            
          </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 6, offset: 0 }}>
            <p className="hora_agenda"></p>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 1 }}>
            <p className="nombre_agenda">Sesiones temáticas simultáneas</p>
            </Col>
        </Row>
        <table>
        <tbody>
          <tr>
            <th class="tabla_titulo_actividad" style={{ width: '50%' }}>SALON JOHN JACOB III Y IV</th>
            <th class="tabla_titulo_actividad" style={{ width: '50%' }}>SALON DIAMOND</th>
          </tr>
          <tr>
            <td class="tabla_actvididad">
              <Row>
                <Col span={3}>
                  <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
                <Col span={21}>09:15 - 11:00 Parque Industrial Seguro: retos y experiencias</Col>
              </Row></td>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>9:00 - 10:00 Innovación tecnológica en el suministro eléctrico: claves para la eficiencia en Parques Industriales</Col>
            </Row></td>
          </tr>
          <tr>
            <td></td>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>10:00 - 11:00 Microrredes y redes inteligentes en parques industriales: innovación para la eficiencia energética</Col>
            </Row></td>
          </tr>
          <tr>
            <td class="tabla_actvididad_2" colspan="2">11:00 - 11:30 RECESO </td>
          </tr>
          <tr>
            <td class="tabla_actvididad" rowspan="1"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>11:30 - 13:00 Brainstorming con Japón: financiamiento, promoción de IED y capacitación</Col>
            </Row></td>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>11:30 - 12:15 Parques Industriales 2.0: Energía integrada y cumplimiento de código de red</Col>
            </Row></td>
          </tr>
          <tr>

            <td></td>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>12:15 - 12:45 El futuro del mercado mexicano de sistemas de almacenamiento de energía</Col>
            </Row></td>
          </tr>
          <tr>
            <td class="tabla_actvididad" rowspan=""><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>13:00 - 14:00 Estrategias de marketing digital para la promoción de parques industriales</Col>
            </Row></td>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>12:45 - 13:30 Gas natural como combustible limpio para parques industriales: retos y oportunidades</Col>
            </Row></td>
          </tr>
          <tr>
          <td></td>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>13:30 - 14:00 Conexión agrupada en parques industriales: estrategias de colaboración público-privada</Col>
            </Row></td>
            
          </tr>
          <tr>
            <td class="tabla_actvididad_2" colspan="2">14:00 - 15:30 RECESO</td>
          </tr>
          <tr>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>15:30 - 16:00 Únete al Atlas AMPIP</Col>
            </Row></td>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>15:30 - 16:30 Economía circular y parques industriales</Col>
            </Row></td>
          </tr>
          <tr>
            <td></td>
            <td class="tabla_actvididad"><Row>
              <Col span={3}>
                <img className="no_ver_chico" src={img_reloj_2} style={{ width: '30px' }} /> </Col>
              <Col span={21}>16:30 - 17:30 Gestión eficiente del agua en Parques Industriales: trámites y regulaciones</Col>
            </Row></td>
          </tr>
          <tr>
            <td class="tabla_actvididad_2" colspan="2">FIN DE LA JORNADA</td>
          </tr>
        </tbody>
      </table>
        </>
        }
        {visualizar_agenda}

        <br />
        <br />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }} style={{ display: 'none' }}>
        <p className="txt_cabos_agenda color_azul">
          <strong>HORARIO </strong> Ciudad de México / <strong>CÓDIGO DE VESTIMENTA:</strong> CASUAL DE NEGOCIOS
        </p><br /><br />
      </Col>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </Row>
  );
};
export default Agenda;
