import React, { useState } from "react";
import { Row, Col } from "antd";
import "../../assets/css/membresia.css";

import registroCerradoImg from "../../assets/images/segunda_2024/registroagotado_ampip2024_movil.jpg";
import registroCerradoImgMovil from "../../assets/images/segunda_2024/registroagotado_ampip2024.jpg";

import banner from "../../assets/images/primera_2025/banner_principal.jpg";


import logo_banner_ergistro from "../../assets/images/primera_2025/registrocerrado_1reunion_2025.png";
import logo_banner_ergistro_2 from "../../assets/images/primera_2025/registrocerradoMovil_1reunion_2025.png";

const RegistroCerrado = (props) => {
  
  const { idioma } = props;

  return (
    <>
    <Row>
        <Col xs={0} lg={24}>
          <Row style={{ position: "relative" }}>
            <Col span={24} style={{ position: "relative" }}>
              <img src={banner} style={{ width: "100%" }} className="curva" />
            </Col>
            <Col
              span={24}
              offset={0}
              className="contenedor_banner_menu"
              style={{ position: "absolute", width: "100%" }}
            >
              <Row justify="center">
                <Col xs={22} md={18}>
                  <Row justify={'space-around'} align={'middle'}>
                    <Col xs={{offset:0,span:0}}  md={{offset:0,span:22}}>
                      <img src={logo_banner_ergistro} style={{ width: "100%" }} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={0}>
          <Row style={{ position: "" }}>
            <Col
              span={24}
              offset={0}
              className="contenedor_banner_menu_2"
              style={{ position: "", width: "100%" }}
            >
              
              <Row justify="center">
                <Col xs={22} md={18}>
                <img src={logo_banner_ergistro} style={{ width: "100%" }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row align="middle" style={{display:'none'}}>
      
      <Col xs={0} md={24}>
        <img
            alt="Registro Cerrado"
            src={registroCerradoImgMovil}
            style={{ width: "100%" }}
          />
      </Col>
      <Col xs={24} md={0}>
        <img
            alt="Registro Cerrado"
            src={registroCerradoImg}
            style={{ width: "100%" }}
          />
      </Col>
    </Row>
      </>
    
  );
};
export default RegistroCerrado;
